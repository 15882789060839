@import url('https://fonts.googleapis.com/css?family=Gentium+Basic');
@import 'menu';

body {
  font-family: 'Gentium Book Basic', serif;
}

a {
  &, &:link, &:hover, &:active, &:focus {
    color: #808080;
  }
  &:visited {
    color: #2F4F4F;
  }
}

blockquote {
  border-left: 4px solid #ccc;
  margin: 30px 0 30px 15px;
  padding-left: 15px;
}

.jumbotron {
  text-align: center;
  padding: 2rem 1rem;
  padding-bottom: 0;
  margin-bottom: 2rem;
  border-radius: 0;
  background-color: #fff;
  position: relative;
  border-bottom: 1px solid #e0e1e3;

  &:before {
    position: absolute;
    z-index: -1;
    width: 99%;
    bottom: 0;
    height: 10px;
    left: .5%;
    border-radius: 100px / 2px;
    box-shadow: 0 0 6px rgba(0,0,0,0.1);
    content: '';
  }
}
.container-fluid {
  -webkit-columns: 3 350px;
  -moz-columns: 3 350px;
  columns: 3 350px;
  -webkit-column-rule: 1px dotted #ddd;
  -moz-column-rule: 1px dotted #ddd;
  column-rule: 1px dotted #ddd;
}
.columned-list {
  li {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}
.people-list {
  div {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

.navigation {
  span:nth-child(1) {
    margin-right: 6px;
  }
  span:nth-child(2) {
    margin-left: 6px;
  }
}

.donation {
  margin-top: 24px;
  text-align: center;
  & > p:first-of-type {
    margin: 10px 0 0;
  }
}

.archive-index {
  ul {
    list-style-type: none;
  }
}

.archive-container.container,
.wiki-container.container {
  max-width: 850px;
}

.archive-container hr {
  overflow: visible; /* For IE */
  padding: 0;
  border: none;
  border-top: medium double #333;
  color: #333;
  text-align: center;
  // max-width: 600px;
  &.article-separator {
    margin-top: 1em;
    margin-bottom: 1.5em;
    border-color: #888;
    max-width: 500px;
  }
  &.header-separator {
    margin-top: 2em;
    margin-bottom: 3em;
    &:after {
      content: "§";
      display:
      inline-block;
      position: relative;
      top: -0.7em;
      font-size: 1.5em;
      padding: 0 0.25em;
      background: white;
    }
  }
}

.archive-container article {
  header {
    h1 {
      margin-bottom: 0;
    }
    span {
      display: block;
      &.archive-author {
        font-size: 1.5rem;
      }
      &.archive-notes {
        margin-top: 1.5rem;
        font-size: 0.8em;
      }
    }
  }
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }
}

.wiki-container.container {
  h1, h2, h3, h4 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
  }
  h1 {
    margin-bottom: 1rem;
  }
  h2 {
    margin-bottom: 0.75rem;
  }

  li, a {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}

#markdown-toc {
  border: 1px solid #aaa;
  padding: 0.5em 0.5em 0.5em 2em;
  list-style: decimal;
  display: inline-block;
  max-width: 250px;
  &::before {
    content: "Contents";
    font-weight: bold;
  }
  ul {
    list-style: decimal;
    padding-left: 1.5em;
  }
}

.breadcrumb {
  background: none;
  padding: 0;

  a {
    text-decoration: underline;
    margin-right: 4px;
  }
}

.wiki-image {
  max-width:100%;
}

.wiki-image-centered-half {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  max-width:50%;
}
